// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-home-home-js": () => import("./../src/pages/home/home.js" /* webpackChunkName: "component---src-pages-home-home-js" */),
  "component---src-pages-home-sections-contact-js": () => import("./../src/pages/home/sections/contact.js" /* webpackChunkName: "component---src-pages-home-sections-contact-js" */),
  "component---src-pages-home-sections-footer-js": () => import("./../src/pages/home/sections/footer.js" /* webpackChunkName: "component---src-pages-home-sections-footer-js" */),
  "component---src-pages-home-sections-hero-js": () => import("./../src/pages/home/sections/hero.js" /* webpackChunkName: "component---src-pages-home-sections-hero-js" */),
  "component---src-pages-home-sections-index-js": () => import("./../src/pages/home/sections/index.js" /* webpackChunkName: "component---src-pages-home-sections-index-js" */),
  "component---src-pages-home-sections-portfolio-js": () => import("./../src/pages/home/sections/portfolio.js" /* webpackChunkName: "component---src-pages-home-sections-portfolio-js" */),
  "component---src-pages-home-sections-skills-js": () => import("./../src/pages/home/sections/skills.js" /* webpackChunkName: "component---src-pages-home-sections-skills-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

